import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpRequest,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import * as XLSX from "xlsx";

@Injectable()
export class MinioService {
  private minioEndpoint = environment.minioUrl;
  private minioAccessKey = environment.minioAccessKey;
  private minioSecretKey = environment.minioSecretKey;
  private bucketName = environment.bucketName;

  uploadProgress: number = 0;

  constructor(private http: HttpClient) {}

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);

    const minioUrl = `${this.minioEndpoint}/${this.bucketName}/${file.name}`;
    const request = new HttpRequest("PUT", minioUrl, formData, {
      reportProgress: true,
    });

    return this.http.request<any>(request);
  }
  readXLSXFile(file: File): Observable<any> {

    return new Observable((observer) => {
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const data: string = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
        const sheetName: string = workbook.SheetNames[0];
        const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const headers = jsonData[0];

        const headerLower = headers.map(item => item.toLowerCase());

        const dataRows = jsonData.slice(1);


        const arrayOfObjects = dataRows.map((row) => {
          const obj = {};
          headerLower.forEach((header, index) => {

          const headerCamelCase = this.spaceToCamelCase(header)
            obj[headerCamelCase] = row[index];
          });
          console.log(obj);
          return obj;
        });

      
        const fileToUploadData = {
          "url": file.name,
          "count": dataRows.length,
          "fileData": arrayOfObjects,

        }
        this.uploadData(fileToUploadData).subscribe(
          (response) => {
            console.log("API call successful:", response);
            observer.next(response);
            observer.complete();
          },
          (error) => {
            console.error("API call error:", error);
            observer.error(error);
          }
        );
      };

      reader.onerror = (error) => {
        observer.error(error);
      };

      reader.readAsBinaryString(file);
    });
  }

  sendFileName(fileName: string): Observable<any> {
    const url = environment.API_URL.fileBaseUrl;
    const payload = { url: fileName };

    return this.http.post<any>(url, payload);
  }

  private uploadData(data: any): Observable<any> {
    const url = environment.API_URL.fileBaseUrl;
    return this.http.post<any>(url, data);
  }
  private  spaceToCamelCase(str: any): string {
   
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
  }
}
