import {Injectable} from '@angular/core';
import {ApiHandlerService} from '../api-handler.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {TokenService} from '../token.service';
import {DatePipe} from '@angular/common';

@Injectable()
export class NipSearchLogService {

    constructor(private apiHandler: ApiHandlerService,
                private token: TokenService,
                private datePipe: DatePipe) {
    }

    public NIP_DRS_URL = environment.API_URL.base_nip_drs;

    /**
     * @Desc Get transactions
     * @returns {Observable<any>}
     */
    public getTransactions = (sessionId: string): Observable<any> => {
      let extra: string = '/' + sessionId;
        return this.apiHandler.get(`${this.NIP_DRS_URL + environment.API_URL.nip_report + extra}`);
    }


    /**
     * @Desc log transaction for reversal
     * @param transactionObject
     * @returns {Observable<any>}
     */
    public logTransactionForReversal = (transactionObject: any): Observable<any> => {
        return this.apiHandler.post(`${this.NIP_DRS_URL + environment.API_URL.reversal_transactions }`, transactionObject);
    }

    /**
     * @Desc log transaction for dispute
     * @param transactionObject
     * @returns {Observable<any>}
     */
    public logTransactionForDispute = (transactionObject: any): Observable<any> => {
      return this.apiHandler.post(`${this.NIP_DRS_URL + environment.API_URL.dispute_transactions }`, transactionObject);
    }

    public transformDate(date) {
      return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss'); // whatever format you need.
    }

}

