import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { TokenService } from "../../services/token.service";
import { Cache } from "../../utils/cache";

@Injectable()
export class PapssAuthGuard implements CanActivate {
  constructor(private router: Router, private _tokenService: TokenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this._tokenService.getAuthUser() &&
      Cache.get("USER") &&
      Cache.get("USER").role === "SUPER_ADMIN"
    ) {
      console.log("here ", Cache.get("USER") && Cache.get("USER").role);
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigateByUrl("/login");
    console.log("here ", Cache.get("USER") && Cache.get("USER").role);
    return false;
  }
}
