import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DrsModalService {
  private modals: any[] = [];

  constructor(private modalService: NgbModal) {}

  open(content: any, options: any = null): void {
    const modalRef = this.modalService.open(content, { ...options });
    this.modals.push(modalRef);
  }

  closeAll(): void {
    this.modals.forEach((modal) => modal.close());
    this.modals = [];
  }
}
