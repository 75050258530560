export const environment = {
  production: true,
  enableBulkOperations: true,
  API_KEY: '',
  API_URL: {
    base: `${window.location.protocol}//${window.location.hostname}:`,
    base_auth: '8762/auth/',
    base_admin: '8762/admin/',
    base_nip_drs: '8762/nip/',
    login: 'login',
    user: 'users',
    institution: 'institutions',
    audit: 'audit',
    password: 'password/update-password',
    reset_password: 'password/reset',
    reset_validate_recovery_code: 'password/validate-recovery-code',
    reset_password_update: 'password/update',
    nip_report: 'nipreport',
    dispute_transactions: 'transactions/dispute',
    dispute_dashboard: 'transactions/dispute/dashboard',
    reversal_transactions: 'transactions/reversal',
    reversal_dashboard: 'transactions/reversal/dashboard',
    bulk_upload: 'bulk/upload',
    bulk_upload_by_id: 'bulk/upload/transactions',
    bulk_reversals: "bulk/reversals",
    fileBaseUrl:"http://196.6.103.206:8762/nip/bulk/upload",
    onboard_institutions_base: 'http://127.0.0.1:8072/api/onboard/',
    onboard_institution: 'createInstitution',
    onboarded_institutions: 'allInstitutions',
    notify_onboard: 'notify',
    created_institutions: 'allCreatedInstitutions',
    approve: 'approveInstitution',
    papss_institution: 'http://127.0.0.1:8077/api/institutions/',
    nibss_papss_institution: 'http://127.0.0.1:8077/api/papss/institutions/',
    base_historical: "8762/nip/",
    easypay_onboard_institutions_base: "http://127.0.0.1:8072/api/easypay/"
  },
  minioUrl:"http://10.3.3.161:9000", //nibss
  minioAccessKey: "AKaHEgQ4II0S7BjT6DjAUDBD9C", //nibss
  minioSecretKey:"SKFzHq5iDoQgF7gyPYRFhzNMYSvY4FFxYz", //nibss
  bucketName:"drs-bulk-reversal",
};