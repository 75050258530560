import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {TokenService} from './token.service';
import {environment} from '../environments/environment';
import {NotificationService} from './notification.service';



@Injectable()
export class ApiInterceptorService implements HttpInterceptor {

    constructor(private Alert: NotificationService, private tokenService: TokenService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const minioEndpoint = environment.minioUrl;
        const minioAccessKey = environment.minioAccessKey;
        const minioSecretKey = environment.minioSecretKey;
        const bucketName = environment.bucketName;

        const setHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        if (req.headers['Content-Type'] !== 'application/json') {
            delete setHeaders['Content-Type'];
        }

        req = req.clone({setHeaders});


        if (this.tokenService.isLoggedIn()) {
            req = req.clone({headers: req.headers.set('Authorization', this.tokenService.getAuthUserToken())});
        }

        if (req.url.includes(`${minioEndpoint}/${bucketName}`) && this.tokenService.isLoggedIn()) {

            const headers = req.headers
                .set('Authorization', 'AWS4-HMAC-SHA256 Credential=AKaHEgQ4II0S7BjT6DjAUDA4BX/20240311/us-east-1/s3/aws4_request,SignedHeaders=host;x-amz-content-sha256;x-amz-date,Signature=33295ec18dd6dddee019f4ed5b3e377cae3249057bb780581f9477707743f2f5')
                .set("X-Amz-Date", "20240311T122320Z")
                .set('X-Amz-Content-Sha256', "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855")
          


            req = req.clone({ headers });
        }

        return next.handle(req);
    }

}
