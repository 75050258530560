import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { ApiHandlerService } from "./api-handler.service";
import { TokenService } from "./token.service";
import { Cache } from "../utils/cache";
import { environment } from "../environments/environment";
import { FormGroup, ValidatorFn } from "@angular/forms";

@Injectable()
export class AuthenticationService {

  public loggedIn: boolean;

  public authenticationUrls = {
    login: environment.API_URL.base_auth + environment.API_URL.login,
    sendPasswordRecoveryCode:
      environment.API_URL.base_admin + environment.API_URL.reset_password,
    validatePasswordRecoveryCode:
      environment.API_URL.base_admin +
      environment.API_URL.reset_validate_recovery_code,
    changePassword:
      environment.API_URL.base_admin +
      environment.API_URL.reset_password_update,
    minioUrl: environment.minioUrl + environment.bucketName,
    getInstitutions: environment.API_URL.base_admin + "institutions",
  };
  public minioUrl;

  constructor(
    private apiHandler: ApiHandlerService,
    private tokenService: TokenService
  ) {}

  public login(data) {
    const path = this.authenticationUrls.login;
    return this.apiHandler.post(path, data);
  }

  //get all institutions
  public getAllInstitutions() {
    const path = this.authenticationUrls.getInstitutions;
    return this.apiHandler.get(path);
  }

  //   public minioApiCall(data) {
  //     const minioHandler = this.apiHandler.clone();
  //     const path = this.authenticationUrls.minioUrl;
  //     return this.apiHandler.post(path, data);
  //   }

  public sendPasswordRecoveryCode(data) {
    const path = this.authenticationUrls.sendPasswordRecoveryCode;
    return this.apiHandler.post(path, data);
  }

  public validatePasswordRecoveryCode(data) {
    const path = this.authenticationUrls.validatePasswordRecoveryCode;
    return this.apiHandler.post(path, data);
  }

  public changePassword(data) {
    const path = this.authenticationUrls.changePassword;
    return this.apiHandler.put(path, data);
  }

  public logout() {
    this.tokenService.removeAuthUser();
    this.tokenService.removeUserSession();
    this.loggedIn = false;
    Cache.clear();
  }

  /**********PASSWORD SECTION *******/

  /**
   *
   * @param email
   * @returns {Observable<any>}
   * takes a user's email and resets their password
   */
  public resetPasswordByEmail(email) {
    return this.apiHandler.post("password/reset", email);
  }

  /**
   *
   * @returns {Observable<any>}
   * get all password policies
   */
  public getAllPolicys() {
    return this.apiHandler.get("/password-policy");
  }

  /**
   *
   * @returns {Observable<any>}
   * creates a new policy
   */
  public createPolicy(policyData) {
    return this.apiHandler.post("/password-policy", policyData);
  }

  /**
   *
   * @returns {Observable<any>}
   * updates a policy
   */
  public updatePolicy(policyData) {
    return this.apiHandler.put("/password-policy", policyData);
  }

  /**
   *
   * @returns {Observable<any>}
   * gets all tasks for a user
   */
  public getUserTasks() {
    return this.apiHandler.get("/user/task");
  }

    /**
     *
     * @returns {Observable<any>}
     * gets all tasks for a user
     */
    public getUserAgreementStatus() {
        return this.apiHandler.get('8762/nip/bulk/reversal/user/agreement/status');
    }

     /**
     *
     * @returns {Observable<any>}
     * gets all tasks for a user
     */
     public updateUserAgreementStatus(status) {
        return this.apiHandler.put(`8762/nip/bulk/reversal/user/agreement/update/${status}`);
    }
}
