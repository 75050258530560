import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import {AuthenticationService} from './authentication.service';
import { DrsModalService } from './modal.service';
import {Cache} from '../utils/cache';

const MINUTES_UNITL_AUTO_LOGOUT = 10; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = 'lastAction';

@Injectable()
export class AutoLogoutService {

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private ngZone: NgZone,
    private modalSerivce: DrsModalService
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  get lastAction() {
    let lastActionValue = Cache.get(STORE_KEY);
    if (lastActionValue == null){
      this.reset();
    }
    lastActionValue = Cache.get(STORE_KEY);
    return parseInt(lastActionValue);
  }
  set lastAction(value) {
    Cache.set(STORE_KEY, value);
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    });
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        console.log(`User logged out after ${MINUTES_UNITL_AUTO_LOGOUT} minutes.`);
        this.auth.logout();
        this.modalSerivce.closeAll()
        this.router.navigateByUrl('/login');
      }
    });
  }
}
