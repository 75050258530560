import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
  TemplateRef
} from "@angular/core";
import { MagicClasses } from "../../../shared/magic-methods/classes";
import { SharedService } from "../../../services/apis/setup-service";
import { Router } from "@angular/router";
import { NotificationService } from "../../../services/notification.service";
import { TokenService } from "../../../services/token.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { FormBuilder } from "@angular/forms";
import { Cache } from "../../../utils/cache";
import { UsersService } from "../../../services/apis/users.service";
import { ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DrsModalService } from "../../../services/modal.service";
import { MinioService } from "../../../services/upload-file-to-minio";
import Swal from "sweetalert";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent
  extends MagicClasses
  implements OnInit, AfterViewInit
{
  institutionName: string;
  isNotSuperAdminUser: boolean;
  isSuperAdminUser: boolean;
  changePassword: boolean;
  isBulkReversalDisabled : boolean = true;
  canUserViewBulkUpload: boolean = Cache.getStatus("status") === "false" ? false : Cache.getStatus("status") === "true" ? true : false;
  canUserBulkUploadFile: boolean = Cache.getStatus("status") === "false" ? false : Cache.getStatus("status") === "true" ? true : false;

  userDetail: any;
  userId;
  role;
  closeResult: string;
  uploadProgress: number = 0;
  isAdmin: boolean;
  bulkUploadErrors: any = null;
  didBulkUploadFail: boolean =  false;
  enableBulkOperations: boolean = environment.enableBulkOperations;

  @ViewChild("fileInput") fileInput: any;
  @ViewChild("errorModalTemplate") errorModalTemplate: TemplateRef<any>;
  @ViewChild("successModalTemplate") successModalTemplate: TemplateRef<any>;

  constructor(
    private _sharedService: SharedService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private Alert: NotificationService,
    private _tokenService: TokenService,
    private _authService: AuthenticationService,
    private _userService: UsersService,
    private renderer: Renderer2,
    private el: ElementRef,
    private modalService: DrsModalService,
    private minio: MinioService
  ) {
    super(_sharedService);
   this.canUserViewBulkUpload = Cache.getStatus("status") === "false" ? false : Cache.getStatus("status") === "true" ? true : false;
  }

  ngOnInit() {
    this.institutionName = Cache.get("USER").institutionName;
    this.userId = Cache.get("USER").userId;
    this.role = Cache.get("USER").role;
    this.isNotSuperAdminUser = this.institutionName !== "NIBSS";
    this.isSuperAdminUser = this.role === 'SUPER_ADMIN';
    this.changePassword = Cache.get("USER").changePassword;
    this.userAgreementStatus();
  }

  ngAfterViewInit() {
    //this.openFileInput();
    this.isAdmin = this.role === 'ADMIN' ? true : false;
    // Access the file input element after the view has been initialized
    if (this.fileInput) {
      const fileInput = this.fileInput.nativeElement;
      this.renderer.listen(fileInput, "change", (event) =>
        this.handleFileChange(event)
      );
    }
  }

  public logoutUser() {
    this._authService.logout();
    this._router.navigateByUrl("/");
  }

  public routeByUrl(url: string) {
    this._router.navigateByUrl(url);
  }

  public getUserDetail() {
    this._userService.getUserById(this.userId).subscribe(
      (response) => {
        this.userDetail = response;
      },
      (error) => {
        if (error.status === 0) {
          this.Alert.error(
            "Please check your INTERNET CONNECTION, and try again"
          );
        } else {
          this.Alert.error("Unable to change password", error);
        }
      }
    );
  }

  public open(content) {
    this.getUserDetail();
    this.modalService.open(content);
  }

  public triggerErrorModal(content){
    this.modalService.open(content, { centered: true })
  }

  public triggerSuccessModal(content) {
    this.triggerErrorModal(content);
  }

  public openBulkUpload(content, agreement) {

    this.getUserDetail();
    this.canUserBulkUploadFile = Cache.getStatus("status") === "false" ? false : Cache.getStatus("status") === "true" ? true : false;
    if(this.canUserBulkUploadFile){
      this.modalService.open(content);
      this.bulkUploadErrors = null
    }else{
      this.modalService.open(agreement);
      this.bulkUploadErrors = null
    }


  }

  onLogoClick(event: Event): void {
    event.preventDefault();
  }

  downloadFile(): void {
    const filePath = '../../../assets/template.xlsx';
    fetch(filePath)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = "#" + url;
        anchor.download = 'Bulk-Reversal-Upload-Template.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error downloading file:', error));

  }

  public canViewBulkUpload(content) {
    this.canUserViewBulkUpload = Cache.getStatus("status") === "false" ? false : Cache.getStatus("status") === "true" ? true : false;

    if (this.canUserViewBulkUpload) {
      this._router.navigate(["/nip/bulk-reversal"]);
    } else{
      this.modalService.open(content);
    }
  }

  openFileInput(): void {
    if (navigator.userAgent.indexOf("Safari") !== -1) {
      const newFileInput = document.createElement("input");
      newFileInput.type = "file";
      newFileInput.style.display = "none";

      document.body.appendChild(newFileInput);
      newFileInput.click();
      newFileInput.onchange = (event) => {
        const fileInput = event.target as HTMLInputElement;
        const file = fileInput.files ? fileInput.files[0] : null;
        if (file) {
          this.handleFileChange(file);
        }
        document.body.removeChild(newFileInput);
      };
    } else {
      const fileInput = document.querySelector(
        "#fileInput"
      ) as HTMLInputElement;
      const file = fileInput.files ? fileInput.files[0] : null;

      this.handleFileChange(file);
    }
  }

  handleFileChange(file: File): void {
    const regex =
      /^\d+_(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})\.[a-zA-Z]{3,4}$/;
    const isMatch = regex.test(file.name);
    let generatedFilename: any;

    if (!isMatch) {
      generatedFilename = this.generateFilename();
    }

    Swal({
      title: "Confirm Upload",
      text: "Are you sure you want to upload the file?",
      icon: "info",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, I do",
          value: true,
          visible: true,
          className: "swal-btn",
          closeModal: false,
        },
      },
      content: {
        element: "progress",
        attributes: {
          value: this.uploadProgress.toString(),
          max: "100",
          style: "display: none",
        },
      },
    })
    .then((result) => {
      if (result) {
        const progressElement = document.querySelector(".swal2-progress");

        if (progressElement) {
          progressElement.setAttribute("style", "");
        }
        if (file) {
          const startTime = new Date().getTime();
          this.minio.readXLSXFile(file).subscribe(
            (event: any) => {
              console.log("EVENT", event);
              if (event.status === "success") {
                const endTime = new Date().getTime();
                const elapsedTime = endTime - startTime;
                const estimatedTimeRemaining = 10000 - elapsedTime;
                setTimeout(
                  () => {

                    Swal.close();
                    this.modalService.closeAll();
                    this.triggerSuccessModal(this.successModalTemplate);
                  },
                  estimatedTimeRemaining > 0 ? estimatedTimeRemaining : 0
                );
              } else {

                if (event.total) {
                  this.uploadProgress = Math.round(
                    (100 * event.loaded) / event.total
                  );

                  const progressElement =
                    document.querySelector(".swal2-progress");
                  if (progressElement) {
                    progressElement.setAttribute(
                      "value",
                      this.uploadProgress.toString()
                    );
                  }
                  Swal.stopLoading();
                }
              }
            },
            (error) => {
              this.didBulkUploadFail = true;
              if(this.didBulkUploadFail){
                this.bulkUploadErrors = error.error.errors;
                this.triggerErrorModal(this.errorModalTemplate)
              }
              console.error("Error uploading file:", error);
              // Close the loading state on error
              Swal.close();
            }
          );
        }
      }
    })
    .catch((error) => {
      console.error("SweetAlert result Error:", error);
      this.Alert.error("File upload was not successful");
    });
    this.modalService.closeAll();
  }

  public navigateTo() {
    this._router.navigateByUrl('/nip/bulk-reversal');
    this.modalService.closeAll();
  }


  sendFileName(fileName: string): void {
    this.minio.sendFileName(fileName).subscribe(
      (response) => {
        console.log("File name sent successfully:", response);
        this.Alert.success("File uploaded successfully");
      },
      (error) => {
        console.error("Error sending file name:", error);
        this.Alert.error("FileName upload was not successful");
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  generateFilename() {
    const institutionCode = Cache.get("institutionCode");
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const filename = `${institutionCode}_${year}${month}${day}${hours}${minutes}${seconds}.xlsx`;
    return filename;
  }

  public userAgreementStatus() {
    this._authService.getUserAgreementStatus().subscribe(
      (status) => {
        Cache.setStatus("status", status);
      },
      (error: HttpErrorResponse) => {

        this.Alert.error(error.error.message);
      }
    );
  }

  public userAgreed() {
    let agreement = "ACKNOWLEDGED";
    this._authService.updateUserAgreementStatus(agreement).subscribe(
      (status) => {
        this.modalService.closeAll();
        Cache.setStatus("status", status);

      },
      (error: HttpErrorResponse) => {
        this.Alert.error(error.error.message);

        this.modalService.closeAll();
      }
    );
  }
}
