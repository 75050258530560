import { Injectable } from "@angular/core";
import { ApiHandlerService } from "../api-handler.service";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/Observable";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PapssInstitution {
  constructor(
    private apiHandler: ApiHandlerService,
    private http: HttpClient
  ) {}

  private PAPSS_INSTITUTION_URL = environment.API_URL.nibss_papss_institution;
  private INSTITUTION_URL = environment.API_URL.papss_institution;

  // private apiUrl = `${environment.API_URL.onboard_papss_institution}`;
  /**
   * Get all institutions
   * @returns {Observable<any>}
   */

  getAllInstitutions(page, pageSize = 10): Observable<any> {
    return this.http.get<any>(
      `${this.INSTITUTION_URL}allInstitution?page=${page}&pageSize=${pageSize}`
    );
  }

  getAllPapssInstitutions = (page, pageSize = 10): Observable<any> => {
    return this.http.get(
      `${this.PAPSS_INSTITUTION_URL}allNibssPapss?page=${page}&pageSize=${pageSize}`
    );
  };

  /**
   * create new user function
   * @param institutionObject
   * @returns {Observable<any>}
   */
  onboardInstitution(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.INSTITUTION_URL}addInstitution`,
      payload
    );
  }

  /**
   * @Desc Update user institution
   * @param data
   * @returns {Observable<any>}
   */

  updateInstitution(id: string, payload: any): Observable<any> {
    return this.http.put<any>(`${this.INSTITUTION_URL + id}`, payload);
  }

  updateNibssPapssInstitution(id: string, payload: any): Observable<any> {
    return this.http.put<any>(`${this.PAPSS_INSTITUTION_URL + id}`, payload);
  }

  searchPapssInstitution(val: any): Observable<any> {
    return this.http.get<any>(`${this.INSTITUTION_URL}search/${val}`);
  }

  searchNibssPapssInstitution(val: any): Observable<any> {
    return this.http.get<any>(`${this.PAPSS_INSTITUTION_URL}search/${val}`);
  }

  deleteInstitution(id: string): Observable<any> {
    return this.http.delete<any>(`${this.INSTITUTION_URL + id}`);
  }
}
