import {Injectable} from '@angular/core';
import {ApiHandlerService} from '../api-handler.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsersInstitution {

  private _institutions: any;

  constructor(private apiHandler: ApiHandlerService, private http: HttpClient) { }

  public INSTITUTION_URL = environment.API_URL.base_admin + environment.API_URL.institution;
  public ONBOARD_URL = environment.API_URL.onboard_institutions_base;
  public EASYPAY_URL = environment.API_URL.easypay_onboard_institutions_base;

  /**
   * Get all institutions
   * @returns {Observable<any>}
   */
  public getAllInstitutions = (): Observable<any> => {
    return this.apiHandler.get(`${this.INSTITUTION_URL}`);
  }

  public getAllOnboardedInstitutions = (param: string, pageNumber: number, pageSize: number): Observable<any> => {
    let extra = '?';

    if (typeof param !== 'undefined' && param) {
      extra = extra + 'param=' + param + '&';
    }

    if (typeof pageNumber !== 'undefined' && pageNumber) {
      extra = extra + 'page=' + pageNumber + '&';
    }

    if (typeof pageSize !== 'undefined' && pageSize) {
      extra = extra + 'pageSize=' + pageSize + '&';
    }
    return this.http.get(`${this.ONBOARD_URL}${environment.API_URL.onboarded_institutions}${extra}`)
  }

  public getAllCreatedInstitutions = (): Observable<any> => {
    return this.http.get(`${this.ONBOARD_URL}${environment.API_URL.created_institutions}`)
  }

  public getAllEasypayInstitutions = (param: string, pageNumber: number, pageSize: number): Observable<any> => {
    let extra = '?';

    if (typeof param !== 'undefined' && param) {
      extra = extra + 'param=' + param + '&';
    }

    if (typeof pageNumber !== 'undefined' && pageNumber) {
      extra = extra + 'page=' + pageNumber + '&';
    }

    if (typeof pageSize !== 'undefined' && pageSize) {
      extra = extra + 'pageSize=' + pageSize + '&';
    }
    return this.http.get(`${this.EASYPAY_URL}fetchAllInstitutions${extra}`)
  }

  public easypayOnboard = (institutionObject: any): Observable<any> => {
    return this.http.post(`${this.EASYPAY_URL}onboardInstitution`, institutionObject)
  }

  public toggleEasypayUser = (id: number, enabled: number): Observable<any> => {
    return this.http.post(`${this.EASYPAY_URL}toggleStatus/${id}`, { enabled: enabled === 100 ? false : true })
  }

  public updateEasypayInstitution = (id: number, institutionObject: any) => {
    return this.http.put(`${this.EASYPAY_URL}updateInstitution/${id}`, institutionObject)
  }

  /**
   * create new user function
   * @param institutionObject
   * @returns {Observable<any>}
   */
  public createInstitution = (institutionObject: any): Observable<any> => {
    return this.apiHandler.post(`${this.INSTITUTION_URL}`, institutionObject);
  }

  public onboardInstitution = (institutionObject: any): Observable<any> => {
    return this.http.post(`${this.ONBOARD_URL}${environment.API_URL.onboard_institution}`, institutionObject)
  }

  /**
   * @Desc Update user institution
   * @param data
   * @returns {Observable<any>}
   */
  public updateInstitution = (data: any): Observable<any> => {
    return this.apiHandler.put(`${this.INSTITUTION_URL}`, data);
  }

  /**
   * @Desc Notify other institutions of onboarding
   * @param data
   * @returns {Observable<any>}
   */

  public notify(data: any){
    return this.http.post(`${this.ONBOARD_URL}${environment.API_URL.notify_onboard}`, data)
  }

  /**
   * @Desc Approve profiled institutions to complete onboarding
   * @param data
   * @returns {Observable<any>}
   */

  public approve(data: any){
    return this.http.post(`${this.ONBOARD_URL}${environment.API_URL.approve}`, data)
  }

  /**
   * @Desc Fetch list of institution categories
   * @returns {Observable<any>}
   */

  public getCategories(){
    return this.http.get(`${this.ONBOARD_URL}getCategories`)
  }

  /**
   * @Desc Fetch list of settlement banks
   * @returns {Observable<any>}
   */

  public getSettlementBanks(){
    return this.http.get(`${this.ONBOARD_URL}getSettlementBanks`)
  }

  get institutions(): any {
    if (this._institutions === null) {
      this.apiHandler.get(`${this.INSTITUTION_URL}`).subscribe((response) => {
        this._institutions = response;
      }, (error) => {
        this._institutions = null;
      });
    }

    return this._institutions;
  }

  set institutions(value: any) {
    this._institutions = value;
  }

}
