import { Component } from '@angular/core';
import {AutoLogoutService} from '../services/AutoLogoutService';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event} from '@angular/router';





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'drsfrontend';
  showLoadingIndicator = true;

  constructor(private autoLogout: AutoLogoutService, private _router: Router) {

    this._router.events.subscribe((routerEvent: Event) => {
      console.log('routing.......');
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
      }

      if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel) {
        this.showLoadingIndicator = false;
      }
    });

  }

  
}
