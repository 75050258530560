﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {TokenService} from '../../services/token.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private _tokenService: TokenService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
         if (this._tokenService.getAuthUser()) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigateByUrl('/login');
        return false;
    }
}
